$breakpoints: (
  sm: 575px,
  lg: 991px,
);

.btn-icon-single {
  .btn-text {
    font-weight: 500;
  }
  @media (max-width: map-get($breakpoints, sm)) {
    .btn-text {
      display: none;
    }
  }
}
