@keyframes show {
  from {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0);
  }
}

.custom_title {
  z-index: 9999;
  background-color: inherit;
  animation: show .2s ease-in-out;
}

.hide {
  animation: hide .2s ease-in-out !important;
}