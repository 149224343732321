.custom-dropdown-body {
  overflow: hidden;

  &_closed {
    transition: max-height 0.25s linear;
    max-height: 0px;
  }

  &_open {
    transition: max-height 1s;
    max-height: 700px;
  }
}