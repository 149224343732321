$breakpoints: (
  lg: 991px,
);

.login-form__container {
  width: 100%;
  max-width: 330px;
  padding: 28px;
  margin: 0px auto;
  margin-top: 20px;
  max-height: 500px;

  @media (min-width: map-get($breakpoints, lg)) {
    padding: 20px;
    margin-top: 50px;
  }

  @media screen and (max-height: 600px) {
    margin-top: 8px;
  }

  &__logo {
    margin-bottom: 48px;

    @media screen and (max-height: 600px) {
      margin-bottom: 24px;
    }
  }

  &__up-header {
    &__text-block {
      min-height: 32px;
      justify-content: flex-end;
      &__inobitec-text {
        color: #626266;
        font-size: 8px;
        margin-bottom: -2px;
        text-transform: uppercase;
      }
  
      &__dicom-text {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        margin-top: -2px;
        margin-bottom: -4px;
      }
    }
  }

  &__header {
    font-weight: 600 !important;
    font-size: 24px;
    line-height: 24px;
  }

  &__input-label {
    margin-top: 28px;
    color: #636B7D;
    font-size: 11px;
    line-height: 16px;
    font-weight: 500;
    height: 24px;

    &__pwd {
      margin-left: -32px;
      margin-top: 10px;
    }
  }

  &__bottom-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 36px;
    margin-bottom: 16px;

    &__checkbox {
      padding-top: 9px;
    }

    &__forgot {
      color: #256ACC;
      font-size: 11px;
      font-weight: 500;
      cursor: pointer;
      text-align: end;
    }

    &__remember-me {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: static;
      color: #636B7D;
      font-size: 11px;
      &__text {
        margin-left: -1px;
        margin-top: 8px;
      }
    }
  }

  &__copyright {
    font-size: 11px !important;
    color: #959CAD;
    line-height: 16px !important;
    margin-top: 60px;

    @media screen and (max-height: 600px) {
      margin-top: 20px;
    }
  }

  input {
    height: 40px;
    margin-bottom: '32px';
  }

  input[type='email'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  input[type='password'] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  & input::placeholder {
    color: silver;
  }
}
